<template>
  <div class="hs-personal">
    <div class="hs-personal__body">
      <div class="hs-personal__left">
        <div class="hs-personal__input">
          <Input
            header="Серия паспорта"
            placeholder="Введите данные"
            mask="####"
            v-model="formData.series"
            :disabled="isDisabled"
            :error="v$.formData.series.$errors.length !== 0"
          />
          <Input
            header="Номер паспорта"
            placeholder="Введите данные"
            v-model="formData.number"
            mask="######"
            :disabled="isDisabled"
            :error="v$.formData.number.$errors.length !== 0"
          />
          <Input
            header="Дата выдачи паспорта"
            placeholder="дд.мм.гггг"
            type="date"
            v-model="formData.issue_at"
            :disabled="isDisabled"
            @blur="validateMinDate(formData.issue_at)"
            :error="
              v$.formData.issue_at.$errors.length !== 0 ||
              isAfterToday(formData.issue_at)
            "
          />
          <Input
            header="Кем выдан паспорт"
            placeholder="Введите данные"
            v-model="formData.who_issued_document"
            :disabled="isDisabled"
            :error="v$.formData.who_issued_document.$errors.length !== 0"
          />
          <Input
            header="Код подразделения"
            placeholder="___-___"
            mask="###-###"
            v-model="formData.division_code"
            :disabled="isDisabled"
            :error="v$.formData.division_code.$errors.length !== 0"
          />
          <Input
            header="Место рождения"
            placeholder="Введите данные"
            v-model="formData.place_birth"
            :disabled="isDisabled"
            :error="v$.formData.place_birth.$errors.length !== 0"
          />
        </div>
      </div>
      <div class="hs-personal__right">
        <div class="hs-personal__input">
          <Input
            header="Место жительства: по паспорту"
            info-text="Образец: Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 58, кв. 312"
            placeholder="Введите данные"
            v-model="formData.place_registration"
            :disabled="isDisabled"
            :error="v$.formData.place_registration.$errors.length !== 0"
            tooltip-right
          />
          <Input
            header="Место жительства: фактическое"
            info-text="Образец: Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 58, кв. 312"
            placeholder="Введите данные"
            :disabled="isSameAddresses || isDisabled"
            :readonly="isSameAddresses"
            v-model="formData.place_residence"
            :error="v$.formData.place_residence.$errors.length !== 0"
            tooltip-right
          />
          <Checkbox
            label="Совпадает"
            @update:modelValue="copyAddress"
            :disabled="isDisabled"
          />
        </div>
        <div class="hs-personal__docs">
          <div class="hs-personal__docs-title">Необходимые документы</div>
          <div class="documents__desc">
            <p>Копии прикрепляемых документов должны быть формата А4</p>
            <p>
              Все документы необходимо загружать с персонального компьютера или
              ноутбука во избежание технических неполадок
            </p>
          </div>
          <DocsComponent
            :type="'passport'"
            :project="project"
            :current-project="currentProject"
            :is_disabled="isDisabled"
            :category_id="
              currentCategoryId || currentCategoryData?.info_category.id
            "
            @docs-changed="docsChanged"
            @up-docs="(value) => (passportDocs = value)"
          />
        </div>
        <div class="docs-upload-info" v-if="!isDisabled">
          *файлы с разрешением png, jpg размером не более 2 Мб
        </div>
      </div>
      <div class="hs-personal__btn" v-if="!isDisabled">
        <Button @click="saveInfo" :loading="isBtnLoading">
          Сохранить изменения
        </Button>
        <Button secondary v-if="false">Сохранить как черновик</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import moment from "moment";
import { mapState } from "vuex";
import Checkbox from "@/components/Blocks/Checkbox";
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import validations from "@/enums/validations";
import useVuelidate from "@vuelidate/core";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import DocsComponent from "@/components/Blocks/DocsComponent";
export default {
  name: "HousingStockPersonalData",
  components: { DocsComponent, Button, Checkbox, Input },
  props: ["project", "currentProject"],
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isSameAddresses: false,
      isBtnLoading: false,
      isDateInvalid: false,
      isFormValidate: false,
      passportDocs: [],
      errors: [],
      formData: {
        series: "",
        number: "",
        issue_at: "",
        who_issued_document: "",
        division_code: "",
        place_birth: "",
        place_registration: "",
        place_residence: "",
        document_passport_main_page: "",
        document_passport_register_page: "",
        document_passport_6_7_page: "",
      },
    };
  },
  watch: {
    user() {
      this.loadInfo();
    },
    docs: {
      handler: function () {
        Object.keys(this.formData)
          .filter((i) => {
            return i.startsWith("document");
          })
          .forEach((item) => {
            return (this.formData[item] = this.docs[item]);
          });
      },
      deep: true,
    },
  },
  validations() {
    if (this.user?.passport?.document_files) {
      Object.keys(this.formData).forEach((item) => {
        if (this.user?.passport?.document_files[item]?.full) {
          validations.personalDataHS[item] = {};
        }
      });
    }
    return {
      formData: validations.personalDataHS,
    };
  },

  computed: {
    fieldsInfo() {
      return trayValidationsTexts.personalDataHS;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    isDisabled() {
      let boolean;
      if (this.project) {
        if (Object.values(this.currentCategoryData).length > 0) {
          if (this.validPeriodForRollback) {
            if (this.currentCategoryData?.status?.key === 0) {
              boolean = false;
            } else boolean = this.currentCategoryData?.status?.key !== 35;
          } else if (this.validPeriodFor35Status) {
            boolean = this.currentCategoryData?.status?.key !== 35;
          } else return true;
        }
      }

      return this.isOldApplication || boolean;
    },

    ...mapState("user", ["user", "disabledFields", "setIsPassportMustEdit"]),
    ...mapState([
      "docs",
      "currentCategoryData",
      "statementId",
      "currentCategoryId",
      "isOldApplication",
    ]),
  },
  methods: {
    async setFakeUserData() {
      Object.keys(this.formData)
        .filter((i) => {
          return !i.startsWith("document");
        })
        .forEach((item) => {
          this.user.passport[item] = this.formData[item];
        });
    },
    copyAddress() {
      if (!this.isSameAddresses) {
        this.formData.place_residence = this.formData.place_registration;
        this.isSameAddresses = true;
      } else {
        this.formData.place_residence = "";
        this.isSameAddresses = false;
      }
    },
    loadInfo() {
      if (this.user.passport?.document_files) {
        Object.keys(this.formData).forEach((item) => {
          if (
            this.user.passport?.document_files[item] &&
            this.user.passport?.document_files[item].full
          ) {
            return (this.formData[item] =
              this.user.passport?.document_files[item]);
          } else return (this.formData[item] = this.user.passport[item]);
        });

        this.formData.issue_at = moment(this.user.passport?.issue_at).format(
          "YYYY-MM-DD"
        );
      }
      this.isFormValidate = this.disabledFields["isHSPersonalDataFilled"];
    },
    isAfterToday(value) {
      return moment(value).isAfter(moment());
    },
    validateMinDate(value) {
      if (this.isAfterToday(value)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть позже текущего дня",
          type: "error",
        });
        this.isDateInvalid = true;
      } else this.isDateInvalid = false;
    },
    async saveInfo() {
      if (this.isDateInvalid) return;
      if (this.errors?.length > 0) {
        this.$store.dispatch("validationErrors", {
          errors: this.errors,
          fieldsInfo: this.isAllDocsLabels,
        });
        return;
      }

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }
      if (this.project && !this.isFormValidate) {
        return;
      }
      const needValidationDocsBefore = await this.$store.dispatch(
        "user/getNeedValidationDocuments"
      );

      let payloadObject = {};

      Object.keys(this.formData).forEach((item) => {
        if (this.formData[item] && this.formData[item] !== "Invalid date") {
          payloadObject[item] = this.formData[item];
        }
      });
      payloadObject.issue_at = `${this.formData.issue_at} 00:00:00`;

      if (this.user?.passport?.document_files) {
        Object.keys(payloadObject).forEach((item) => {
          if (
            this.user.passport?.document_files[item]?.full &&
            this.formData[item].full
          ) {
            delete payloadObject[item];
          } else {
            if (this.user.info_validation_documents.data.length > 0) {
              let infoDocument = this.user.info_validation_documents.data.find(
                (i) => {
                  return i.key_document === item;
                }
              );
              if (infoDocument) {
                infoDocument.status = 0;
              }
            }
          }
        });
      }

      this.isBtnLoading = true;

      await createRequest(requestConfigs.POSTSavePassport, {
        jsonPayload: payloadObject,
      })
        .then((response) => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          Object.keys(response.data).forEach((item) => {
            if (!this.user.passport.document_files) {
              this.user.passport.document_files = {};
            }
            this.user.passport.document_files[item] = response.data[item];
          });
          this.setFakeUserData().then(async () => {
            await this.$store.commit(
              "user/setHSPersonalDataFilled",
              Object.values(this.user.passport)?.length > 0 &&
                Boolean(
                  this.user.passport?.document_files?.document_passport_6_7_page
                    ?.full
                ) &&
                Boolean(
                  this.user?.passport?.document_files
                    ?.document_passport_main_page?.full
                )
            );

            if (this.project) {
              const needValidationDocsResult = await this.$store.dispatch(
                "user/getNeedValidationDocuments"
              );
              if (
                needValidationDocsBefore > 0 &&
                needValidationDocsResult === 0
              ) {
                this.$store.commit("setCurrentPopup", {
                  name: "PopupSendStatement",
                  isShown: true,
                  props: { id: this.currentCategoryData?.id },
                });
                return;
              }
              if (this.disabledFields["isHSPersonalDataFilled"]) {
                await this.$router.push({ name: "HousingStockDocs" });
              }
            } else this.$router.push({ name: "Events" });
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error?.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    docsChanged(value, errors) {
      this.isFormValidate = value;
      this.errors = errors;
    },
  },
  mounted() {
    this.loadInfo();
    Object.keys(this.formData).forEach((item) => {
      this.$store.commit("setDocument", ["", item]);
    });
  },
};
</script>

<style lang="scss">
.hs-personal {
  &__body {
    display: grid;
    grid: min-content 1fr / 495px 1fr;
    column-gap: 20px;
    @include adaptive(desktop-mid) {
      display: block;
    }
  }
  &__right {
    grid-row: 2 span;
  }
  &__body &__input {
    max-width: 495px;
    @include adaptive(desktop-mid) {
      max-width: 728px;
    }
    & > div:last-child {
      margin-bottom: 16px;
    }
  }
  &__docs {
    &-title {
      margin-bottom: 15px;
      font-weight: 500;
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
    align-self: flex-start;
    flex-wrap: wrap;
  }
}
</style>
